<template>
    <body id="page-top" class="sub-page">
        <div class="wrap-body">

            <!--////////////////////////////////////Header-->
            <header>
                <div class="wrap-header">

                    <!---Main Header--->
                    <Lang @switchLang="switchLang"></Lang>
                    <div class="main-header" :style="`background: url(${showpic}) center center;background-size: cover;`">
                        <div class="bg-overlay">
                            <!---Hero Content--->
                            <div class="hero-content-wrapper">
                                <div class="hero-content">

                                    <h1 class="hero-lead wow fadeInLeft" data-wow-duration="1.5s">Contact Us</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!---Top Menu--->
                    <top_menu :lang="sellan"></top_menu>

                </div>
            </header>

            <!--////////////////////////////////////Container-->
            <section id="page-content">
                <div class="wrap-container zerogrid">
                    <div class="crumbs">
                        <ul>
                            <!-- <li><a href="index.html">{{ $t('index.home') }}</a></li> -->
                            <li><a href="javascript:;">{{ $t('index.contact') }}</a></li>

                        </ul>
                    </div>
                    <div id="contact-area">
                        <h1 class="">{{ $t('index.contact') }}</h1>
                        <div class="contact-map_wrapper wow fadeIn" data-wow-delay=".1s" data-wow-duration="1.5s">
                            <!-- Map -->
                            <div id="map" style="height: 550px;"></div>

                            <!-- Show Info Button -->
                            <div class="show-info-link">
                                <a href="#" class="show-info"><i class="fa fa-info"></i>Show info</a>
                            </div>
                            <div class="zerogrid">
                                <!-- Address Info -->
                                <div class="xs-5-6 offset-md-1-6 md-1-2 contact-info-wrapper">
                                    <address>
                                        <div class="row">
                                            <!-- Phone -->
                                            <div class="sm-1-2 address-group">
                                                <span>Phone</span>
                                                <p>TEL: +(852) 3467 6888<br>
                                                    FAX: +(852) 3467 6999</p>
                                            </div>
                                            <!-- Address -->
                                            <div class="sm-1-2 address-group">
                                                <span>Address</span>
                                                <p>{{ $t('index.address') }}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <!-- Email -->
                                            <div class="sm-1-2 address-group">
                                                <span>Email</span>
                                                <a href="mailto:info@jiasong.com">info@jiasong.com</a>
                                            </div>
                                            <!-- Hours -->
                                            <div class="sm-1-2 address-group">
                                                <span>Open Hours</span>
                                                <p>Mon-Fri: 9am-6pm</p>

                                            </div>
                                        </div>
                                        <!-- Show Map Button -->
                                        <div class="row show-map-link">
                                            <a href="#" class="show-map"><i class="fa fa-map-o"></i> Show on map</a>
                                        </div>
                                    </address>
                                </div>
                            </div>
                        </div>
                        <div class="contact-main_wrapper">
                            <div class="row">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d416.64452237166046!2d114.17805506495185!3d22.300835522404064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340400ef413ea6f3%3A0x4c74a3c4952781f0!2z5riv5pm25Lit5b-D5ZWG5aC0!5e0!3m2!1szh-TW!2shk!4v1692468192179!5m2!1szh-TW!2shk"
                                    width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                    <div class="contact-main_wrapper">
                        <div class="row">
                            <div class="sm-2-5 ">
                                <div class="wrap-col">
                                    <div class="wow fadeInLeft" data-wow-delay=".1s" data-wow-duration="1s">
                                        <h3>Have any questions? Let's get in touch!</h3>

                                    </div>
                                </div>
                            </div>
                            <div class="sm-2-5 offset-sm-1-5">
                                <div class="wrap-col">
                                    <div id="contact_form" class="wow fadeInUp" data-wow-delay=".1s" data-wow-duration="1s">
                                        <div id="contact_results"></div>
                                        <div id="contact_body">
                                            <label>
                                                <input type="text" name="subject" id="subject" required="true"
                                                    v-model="subject" placeholder="Subject" />
                                            </label>
                                            <label>
                                                <input type="text" name="name" id="name" required="true" v-model="name"
                                                    placeholder="Your Name" />
                                            </label>
                                            <label>
                                                <input type="text" name="phone" id="phone" required="true" v-model="phone"
                                                    placeholder="Your Phone" />
                                            </label>
                                            <label>
                                                <input type="email" name="email" required="true" placeholder="Your Email"
                                                    v-model="email" />
                                            </label>
                                            <label for="field5">
                                                <textarea name="message" id="message" class="textarea-field" required="true"
                                                    v-model="message" placeholder="Message"></textarea>
                                            </label>
                                            <label>
                                                <button class="button button-skin" type="button" @click="submit"
                                                    id="submit_btn">Submit</button>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!--////////////////////////////////////Footer-->





        </div>
    </body>
</template>
<script>
import * as api from "../api/api"
import top_menu from "@/components/top_menu.vue"

import Lang from "@/components/lang.vue"

export default {
    components: {
        top_menu
        , Lang
    },
    data() {
        return {
            sellan: "cn",
            section: "story",
            showpic: "images/contact.jpg",
            message: "",
            email: "",
            phone: "",
            name: "",
            subject: ""
        }
    },
    mounted() {


        if (localStorage.getItem("lang")) {
            this.sellan = localStorage.getItem("lang")
            this.setLang(this.sellan)

        } else {
            localStorage.setItem("lang", "cn")
        }

    },
    methods: {
        setLang(val) {
            console.log(val)
            this.sellan = val
            this.$i18n.locale = val// this.$i18n.locale === 'zh-cn' ? 'en' : 'zh-cn';
            localStorage.setItem("lang", val)
            document.title = this.$t('index.title')// "abcd"
        },
        switchLang(val) {
            this.setLang(val)
        },
        submit() {
            console.log(this.message)
            var form = {
                subject: this.subject,
                firstName: this.name,
                email: this.email,
                phone: this.phone,
                message: this.message
            }
            console.log(form)
            api.postRequest('/admin/contact',
                form).then(resp => {
                    console.log(resp)
                    this.$message.success("Submit successful!");
                })
        }
    }
}
</script>