<template>
    <div id="cssmenu">
        <ul>
            <li class="active"><a href="index.html"><span>Home</span></a>

            </li>

            <!-- <li v-for="item in   columns  " :class="item.children.length == 0 ? 'active' : 'has-sub'"> -->

            <li v-if="showBtn == false" v-for="item, index in   columns" class="has-sub" :key="index">
                <span v-if="index !== openItem" class="submenu-button" @click="showSub(index)"></span>
                <span v-if="index == openItem" class="submenu-button" @click="showSub(-1)"></span>
                <a href="javascript:;">
                    <span v-if="sellan == 'cn'">{{ item.title }}</span>
                    <span v-if="sellan == 'en'">{{ item.titleEn }}</span>
                    <span v-if="sellan == 'sp'">{{ item.titleSp }}</span>
                </a>


                <ul>

                    <li v-for="  i   in   item.children  "><a href="javascript:;" @click="goto(i)">
                            <span v-if="sellan == 'cn'">{{ i.title }}</span>
                            <span v-if="sellan == 'en'">{{ i.titleEn }}</span>
                            <span v-if="sellan == 'sp'">{{ i.titleSp }}</span></a>
                    </li>
                </ul>
            </li>
            <li v-if="showBtn == true" v-for="item, index in   columns" class="has-sub" :key="index">
                <span v-if="index !== openItem" class="submenu-button" @click="showSub(index)"></span>
                <span v-if="index == openItem" class="submenu-button" @click="showSub(-1)"></span>
                <a href="javascript:;">
                    <span v-if="sellan == 'cn'">{{ item.title }}</span>
                    <span v-if="sellan == 'en'">{{ item.titleEn }}</span>
                    <span v-if="sellan == 'sp'">{{ item.titleSp }}</span>
                </a>


                <ul v-if="showBtn == true" :class="index !== openItem ? 'open' : ''"
                    :style="index !== openItem ? 'display: none;' : 'display: block;'">

                    <li v-for="  i   in   item.children  "><a href="javascript:;" @click="goto(i)">
                            <span v-if="sellan == 'cn'">{{ i.title }}</span>
                            <span v-if="sellan == 'en'">{{ i.titleEn }}</span>
                            <span v-if="sellan == 'sp'">{{ i.titleSp }}</span></a>
                    </li>
                </ul>
            </li>
            <!-- <li class="has-sub"><a href="javascript:;"><span>{{ $t('index.about') }}</span></a>
                <ul>
                    <li><a href="javascript:;" @click="goto('/about/story')"><span>{{ $t('index.story') }}</span></a>
                    </li>
                    <li><a href="javascript:;" @click="goto('/about/mission')"><span>{{ $t('index.mission') }}</span></a>
                    </li>
                    <li><a href="javascript:;" @click="goto('/about/vision')"><span>{{ $t('index.vision') }}</span></a>
                    </li>
                </ul>
            </li>
            <li class="has-sub"><a href="javascript:;"><span>{{ $t('index.p0') }}</span></a>
                <ul>
                    <li><a href="javascript:;" @click="goto('/products/1')"><span>{{ $t('index.p1') }}</span></a>
                    </li>
                    <li><a href="javascript:;" @click="goto('/products/2')"><span>{{ $t('index.p2') }}</span></a>
                    </li>
                    <li><a href="javascript:;" @click="goto('/products/3')"><span>{{ $t('index.p3') }}</span></a>
                    </li>
                    <li><a href="javascript:;" @click="goto('/products/4')"><span>{{ $t('index.p4') }}</span></a>
                    </li>
                    <li><a href="javascript:;" @click="goto('/products/5')"><span>{{ $t('index.p5') }}</span></a>
                    </li>
                    <li><a href="javascript:;" @click="goto('/products/6')"><span>{{ $t('index.p6') }}</span></a>
                    </li>
                    <li><a href="javascript:;" @click="goto('/products/7')"><span>{{ $t('index.p7') }}</span></a>
                    </li>
                    <li><a href="javascript:;" @click="goto('/products/8')"><span>{{ $t('index.p8') }}</span></a>
                    </li>
                    <li><a href="javascript:;" @click="goto('/products/9')"><span>{{ $t('index.p9') }}</span></a>
                    </li>
                    <li><a href="javascript:;" @click="goto('/products/10')"><span>{{ $t('index.p10') }}</span></a>
                    </li>
                </ul>
            </li>
            <li class="has-sub"><a href="javascript:;"><span>{{ $t('index.service') }}</span></a>
                <ul>
                    <li><a href="javascript:;" @click="goto('/service')"><span>{{ $t('index.service1') }}</span></a>

                    </li>
                    <li><a href="javascript:;" @click="goto('/service1')"><span>{{ $t('index.service2') }}</span></a>
                    </li>
                </ul>
            </li> -->
            <li class="last"><a href="javascript:;" @click="Contact"><span>{{ $t('index.contact') }}</span></a>
            </li>
            <li class="has-sub"><a href="javascript:;"><span>Language</span></a>
                <ul>
                    <li><a href="javascript:;" @click="setLang('cn')"><span>中文</span></a>
                    </li>
                    <li><a href="javascript:;" @click="setLang('en')"><span>English</span></a>
                    </li>
                    <li><a href="javascript:;" @click="setLang('sp')"><span>Spanish</span></a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>
<script>

import * as api from "../api/api"

export default {
    components: {

    },
    props: {
        'lang': {
            type: String,
            Default: ""
        },

    },
    watch: {
        'lang': {
            immediate: true,
            handler(newVal) {
                console.log("lang" + newVal)


                if (newVal != undefined) {

                    this.sellan = newVal
                    this.get_data()
                }
                else {

                }



            },
            deep: true
        },

    },
    data() {
        return {
            sellan: "cn",
            // lang: 0,
            columns: [],
            openItem: -1,
            showBtn: window.innerWidth <= 767
        }
    },
    created() {
        console.log(window.innerWidth <= 767)


        // location.reload();


    },
    mounted() {

        if (localStorage.getItem("lang")) {
            this.sellan = localStorage.getItem("lang")
            this.setLang(this.sellan)

        } else {
            localStorage.setItem("lang", "cn")
        }
        this.get_data()


    },
    methods: {
        get_data() {
            var lang = 0
            if (this.sellan == "en") {
                lang = 1
            }
            if (this.sellan == "sp") {
                lang = 2
            }
            var params = {
                web: 3
            }
            api.getRequest('/columns/all',
                params).then(resp => {
                    console.log(resp)
                    this.columns = resp.data[0].children
                    // this.setMenu()

                })
        },
        setLang(val) {
            console.log(val)
            this.sellan = val
            this.$i18n.locale = val// this.$i18n.locale === 'zh-cn' ? 'en' : 'zh-cn';
            localStorage.setItem("lang", val)

            this.get_data()
            document.title = this.$t('index.title')// "abcd"
            this.$emit("switchLang", val)
        },
        goto(page) {
            console.log(page)
            if (page.fatherId == 133) {
                this.$router.push("/about/" + page.contentId);
            }
            if (page.fatherId == 137) {
                this.$router.push("/products/" + page.contentId);
            }
            if (page.id == 149) {
                this.$router.push("/service/" + page.contentId);
            }
            if (page.id == 150) {
                this.$router.push("/service1/" + page.contentId);
            }

            if (window.innerWidth <= 767) {
                location.reload();
            }

            // 

        },
        showSub(val) {
            console.log("aa" + val)
            this.openItem = val
        },
        Contact() {
            this.$router.push("/contact/");
        },

    }
}
</script>