import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ElementUI from 'element-ui';
// import enLocale from 'element-ui/lib/locale/lang/en'
// import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

import 'element-ui/lib/element-#1AB394/index.css';
import router from './router'
////数据
import axios from 'axios'
axios.defaults.baseURL = 'https://apec.microbee.cn/api/'
// axios.defaults.baseURL = 'http://localhost:8088/api/'

const service = axios.create({
  baseURL: process.env.VUE_APP_API, // 请求的接口
  timeout: 100000 // 请求超时时间
})

Vue.prototype.$http = service
Vue.prototype.$axios = axios

import md5 from 'md5';
Vue.prototype.$md5 = md5;


Vue.use(VueI18n)
Vue.use(ElementUI)
Vue.config.productionTip = false

const i18n = new VueI18n({
  locale: 'cn', // 语言包
  messages: {
    'cn': require('../public/data/cn.json'), // 中文简体
    'en': require('../public/data/en.json'), // 英文
    'sp': require('../public/data/sp.json') // 西班牙文
  }
});
import App from './App.vue'

Vue.config.productionTip = false

new Vue({
  i18n, router,
  render: h => h(App),
}).$mount('#app')
