<template>
    <div class="wrap-body">

        <!--////////////////////////////////////Header-->
        <header>
            <div class="wrap-header">

                <!---Main Header--->
                <Lang @switchLang="switchLang"></Lang>
                <div class="main-header" style=" background: url(images/main.jpg) center center; background-size: cover;">

                    <div class="bg-overlay">
                        <img src="images/logo.png"
                            style="width: 10%;min-width:  80px;max-width:150px ;opacity: 0.8;margin-top: 20px;margin-left: 20px;" />
                        <!---Hero Content--->
                        <div class="hero-content-wrapper">
                            <div class="hero-content">
                                <h4 class="h-alt hero-subheading wow fadeIn" data-wow-duration="2s" data-wow-delay=".7s">
                                    {{ $t('index.txt1') }}</h4>
                                <h1 class="hero-lead wow fadeInLeft" data-wow-duration="1.5s">{{ $t('index.txt2') }}<br> {{
                                    $t('index.txt3') }}
                                </h1>
                                <a href="#" class="button button-skin wow fadeIn" data-wow-duration="2s"
                                    data-wow-delay="1s">{{ $t('index.txt4') }}</a>
                            </div>
                        </div>
                        <!---Scroller--->
                        <div id="scroll-page-content">
                            <a href="#page-content" class="scroller">
                                <span class="scroller-text">scroll down</span>
                                <div class="scroller-button">
                                    <i class="fa fa-angle-double-down"></i>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <!---Top Menu--->
                <top_menu :lang="sellan"></top_menu>

            </div>
        </header>


        <!--////////////////////////////////////Container-->
        <section id="page-content" class="index-page">
            <div class="wrap-container">
                <!-----------------content-box-1-------------------->
                <section class="content-box box-1">
                    <div class="zerogrid">
                        <div class="wrap-box"><!--Start Box-->
                            <div class="header wow fadeInUp" data-wow-delay=".1s" data-wow-duration="1s">
                                <h2>{{ $t('index.about') }}
                                </h2>
                                <span class="intro">{{ $t('index.showtxt') }}</span>
                            </div>
                            <div class="content">
                                <div class="row">
                                    <div class="lg-1-3 wow fadeInLeft" data-wow-duration=".8s">
                                        <div class="ft-item">
                                            <div class="ft-icon">

                                                <i class="fa fa-calendar" style="margin-top: 21px;color: #5db95d;"></i>
                                            </div>
                                            <h5>{{ $t('index.showtitle1') }}</h5>
                                            <p>{{ $t('index.showtxt1') }}.</p>
                                        </div>
                                        <div class="ft-item">
                                            <div class="ft-icon">
                                                <i class="fa  fa-thumbs-up" style="margin-top: 21px;color: #5db95d;"></i>
                                            </div>
                                            <h5>{{ $t('index.showtitle2') }}</h5>
                                            <p>{{ $t('index.showtxt2') }}</p>
                                        </div>
                                        <div class="ft-item">
                                            <div class="ft-icon">
                                                <i class="fa  fa-mortar-board" style="margin-top: 21px;color: #5db95d;"></i>
                                            </div>
                                            <h5>{{ $t('index.showtitle3') }}</h5>
                                            <p>{{ $t('index.showtxt3') }}.</p>
                                        </div>
                                    </div>
                                    <div class="lg-1-3">
                                        <img src="images/mid.jpg" alt="" class="ft-image-center wow fadeInUp"
                                            data-wow-delay=".1s" data-wow-duration="1s" />
                                    </div>
                                    <div class="lg-1-3 t-right-xs wow fadeInRight" data-wow-duration=".8s">
                                        <div class="ft-item">
                                            <div class="ft-icon">
                                                <i class="fa fa-anchor" style="margin-top: 21px;color: #5db95d;"></i>
                                            </div>
                                            <h5>{{ $t('index.showtitle4') }}</h5>
                                            <p>{{ $t('index.showtxt4') }}.</p>
                                        </div>
                                        <div class="ft-item">
                                            <div class="ft-icon">
                                                <i class="fa  fa-exchange" style="margin-top: 21px;color: #5db95d;"></i>
                                            </div>
                                            <h5>{{ $t('index.showtitle5') }}</h5>
                                            <p>{{ $t('index.showtxt5') }}.</p>
                                        </div>
                                        <div class="ft-item">
                                            <div class="ft-icon">
                                                <i class="fa  fa-recycle" style="margin-top: 21px;color: #5db95d;"></i>
                                            </div>
                                            <h5>{{ $t('index.showtitle6') }}</h5>
                                            <p>{{ $t('index.showtxt6') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <!-----------------content-box-2-------------------->
                <section class="content-box box-2 box-style-2">
                    <div class="zerogrid">
                        <div class="wrap-box"><!--Start Box-->
                            <div class="content wow fadeInLeft" data-wow-delay=".1s" data-wow-duration="1s">
                                <div class="row">
                                    <div class="sm-1-2 offset-sm-2-4">
                                        <blockquote>
                                            <p style="font-size: 22px;text-align: left;">{{ $t('index.vision') }}: <br>{{
                                                $t('index.visiontext') }}</p>
                                        </blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <!-----------------content-box-3-------------------->
                <section class="content-box box-3 box-style-1">
                    <div class="zerogrid">
                        <div class="wrap-box"><!--Start Box-->
                            <div class="header wow fadeInUp" data-wow-delay=".1s" data-wow-duration="1s">
                                <h2>{{ $t('index.p0') }}</h2>
                                <span class="intro">{{ $t('index.p10') }}</span>
                            </div>
                            <div class="content wow fadeIn" data-wow-delay=".1s" data-wow-duration="1s">
                                <div class="row">
                                    <div class="lg-1-4 md-1-3 sm-1-2">
                                        <div class="wrap-col">
                                            <div class="product">
                                                <div class="product-thumbnail"><a href="javascript:;"
                                                        @click="goto('/products/1')"><img src="images/p1.jpg"></a>
                                                </div>
                                                <div class="product-content">
                                                    <div class="row">
                                                        <h2 class="product-title"><a href="javascript:;"
                                                                @click="goto('/products/1')">{{ $t('index.p1') }}</a></h2>

                                                    </div>
                                                    <div class="row">
                                                        <div class="categories">
                                                            <a href="javascript:;" @click="goto('/products/1')"
                                                                rel="tag">Fish meal</a>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="lg-1-4 md-1-3 sm-1-2">
                                        <div class="wrap-col">
                                            <div class="product">
                                                <div class="product-thumbnail"><a href="javascript:;"
                                                        @click="goto('/products/2')"><img src="images/p2.jpg"></a>
                                                </div>
                                                <div class="product-content">
                                                    <div class="row">
                                                        <h2 class="product-title"><a href="javascript:;"
                                                                @click="goto('/products/2')">{{ $t('index.p2') }}</a></h2>

                                                    </div>
                                                    <div class="row">
                                                        <div class="categories">
                                                            <a href="javascript:;" @click="goto('/products/2')"
                                                                rel="tag">Meat and bone meal</a>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="lg-1-4 md-1-3 sm-1-2">
                                        <div class="wrap-col">
                                            <div class="product">
                                                <div class="product-thumbnail"><a href="javascript:;"
                                                        @click="goto('/products/3')"><img src="images/p3.jpg"></a>
                                                </div>
                                                <div class="product-content">
                                                    <div class="row">
                                                        <h2 class="product-title"><a href="javascript:;"
                                                                @click="goto('/products/3')">{{ $t('index.p3') }}</a></h2>

                                                    </div>
                                                    <div class="row">
                                                        <div class="categories">
                                                            <a href="javascript:;" @click="goto('/products/3')"
                                                                rel="tag">Chicken meal</a>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="lg-1-4 md-1-3 sm-1-2">
                                        <div class="wrap-col">
                                            <div class="product">
                                                <div class="product-thumbnail"><a href="javascript:;"
                                                        @click="goto('/products/4')"><img src="images/p4.jpg"></a>
                                                </div>
                                                <div class="product-content">
                                                    <div class="row">
                                                        <h2 class="product-title"><a href="javascript:;"
                                                                @click="goto('/products/4')">{{ $t('index.p4') }}</a></h2>

                                                    </div>
                                                    <div class="row">
                                                        <div class="categories">
                                                            <a href="javascript:;" @click="goto('/products/4')"
                                                                rel="tag">Seaweed meal</a>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="lg-1-4 md-1-3 sm-1-2">
                                        <div class="wrap-col">
                                            <div class="product">
                                                <div class="product-thumbnail"><a href="javascript:;"
                                                        @click="goto('/products/5')"><img src="images/p5.jpg"></a>
                                                </div>
                                                <div class="product-content">
                                                    <div class="row">
                                                        <h2 class="product-title"><a href="javascript:;"
                                                                @click="goto('/products/5')">{{ $t('index.p5') }}</a></h2>

                                                    </div>
                                                    <div class="row">
                                                        <div class="categories">
                                                            <a href="javascript:;" @click="goto('/products/5')"
                                                                rel="tag">Shrimp shell meal</a>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="lg-1-4 md-1-3 sm-1-2">
                                        <div class="wrap-col">
                                            <div class="product">
                                                <div class="product-thumbnail"><a href="javascript:;"
                                                        @click="goto('/products/6')"><img src="images/p6.jpg"></a>
                                                </div>
                                                <div class="product-content">
                                                    <div class="row">
                                                        <h2 class="product-title"><a href="javascript:;"
                                                                @click="goto('/products/6')">{{ $t('index.p6') }}</a></h2>

                                                    </div>
                                                    <div class="row">
                                                        <div class="categories">
                                                            <a href="javascript:;" @click="goto('/products/6')"
                                                                rel="tag">Palm fat powder</a>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="lg-1-4 md-1-3 sm-1-2">
                                        <div class="wrap-col">
                                            <div class="product">
                                                <div class="product-thumbnail"><a href="javascript:;"
                                                        @click="goto('/products/7')"><img src="images/p7.jpg"></a>
                                                </div>
                                                <div class="product-content">
                                                    <div class="row">
                                                        <h2 class="product-title"><a href="javascript:;"
                                                                @click="goto('/products/7')">{{ $t('index.p7') }}</a></h2>

                                                    </div>
                                                    <div class="row">
                                                        <div class="categories">
                                                            <a href="javascript:;" @click="goto('/products/7')"
                                                                rel="tag">Coconut fat powder</a>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="lg-1-4 md-1-3 sm-1-2">
                                        <div class="wrap-col">
                                            <div class="product">
                                                <div class="product-thumbnail"><a href="javascript:;"
                                                        @click="goto('/products/8')"><img src="images/p8.jpg"></a>
                                                </div>
                                                <div class="product-content">
                                                    <div class="row">
                                                        <h2 class="product-title"><a href="javascript:;"
                                                                @click="goto('/products/8')">{{ $t('index.p8') }}</a></h2>

                                                    </div>
                                                    <div class="row">
                                                        <div class="categories">
                                                            <a href="javascript:;" @click="goto('/products/8')"
                                                                rel="tag">Corn germ meal</a>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <!-----------------content-box-4-------------------->
                <section class="content-box box-4">
                    <div class="zerogrid-fluid">
                        <div class="wrap-box">
                            <div class="header wow fadeInUp" data-wow-delay=".1s" data-wow-duration="1s">
                                <h2>{{ $t('index.service') }}</h2>
                                <span class="intro">Our Services</span>
                            </div>
                            <div class="content wow fadeIn" data-wow-delay=".1s" data-wow-duration="1s">
                                <div class="row">
                                    <div class="lg-1-4 md-1-3 sm-1-2">
                                        <div class="portfolio-box zoom-effect">
                                            <img src="images/12.jpg" class="img-responsive" alt="">
                                            <div class="portfolio-box-caption">
                                                <div class="portfolio-box-caption-content">
                                                    <div class="project-name">
                                                        {{ $t('index.service1') }}
                                                    </div>
                                                    <div class="project-category">
                                                        Feed import consulting services.
                                                    </div>
                                                    <div class="project-button">
                                                        <a href="javascript:;" @click="goto('/service')"
                                                            class="button button-skin">Read More</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="lg-1-4 md-1-3 sm-1-2">
                                        <div class="portfolio-box zoom-effect">
                                            <img src="images/13.jpg" class="img-responsive" alt="">
                                            <div class="portfolio-box-caption">
                                                <div class="portfolio-box-caption-content">
                                                    <div class="project-name">
                                                        {{ $t('index.service3') }}
                                                    </div>
                                                    <div class="project-category">
                                                        Chinese Customs GACC declaration
                                                    </div>
                                                    <div class="project-button">
                                                        <a href="javascript:;" @click="goto('/service')"
                                                            class="button button-skin">Read More</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="lg-1-4 md-1-3 sm-1-2">
                                        <div class="portfolio-box zoom-effect">
                                            <img src="images/14.jpg" class="img-responsive" alt="">
                                            <div class="portfolio-box-caption">
                                                <div class="portfolio-box-caption-content">
                                                    <div class="project-name">
                                                        {{ $t('index.service4') }}
                                                    </div>
                                                    <div class="project-category">
                                                        Chinese Ministry of Agriculture and Rural Affairs (MARA)
                                                        declaration
                                                    </div>
                                                    <div class="project-button">
                                                        <a href="javascript:;" @click="goto('/service')"
                                                            class="button button-skin">Read More</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="lg-1-4 md-1-3 sm-1-2">
                                        <div class="portfolio-box zoom-effect">
                                            <img src="images/15.jpg" class="img-responsive" alt="">
                                            <div class="portfolio-box-caption">
                                                <div class="portfolio-box-caption-content">
                                                    <div class="project-name">
                                                        {{ $t('index.service2') }}
                                                    </div>
                                                    <div class="project-category">
                                                        Customized professional services
                                                    </div>
                                                    <div class="project-button">
                                                        <a href="javascript:;" @click="goto('/service1')"
                                                            class="button button-skin">Read More</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <!-----------------content-box-5-------------------->

                <!-----------------content-box-6-------------------->
                <foot></foot>


            </div>
        </section>

        <!--////////////////////////////////////Footer-->

        <div id="page-top"><a href="#page-top" class="button button-toTop f-right"><i class="fa fa-angle-double-up"></i></a>
        </div>



    </div>
</template>
<script>
import top_menu from "@/components/top_menu.vue"

import Lang from "@/components/lang.vue"
import foot from "@/components/foot.vue"
export default {
    components: {
        top_menu,
        Lang,
        foot
    },
    data() {
        return {
            sellan: "cn",
        }
    },
    mounted() {

        // localStorage.setItem("lang", "cn")
        if (localStorage.getItem("lang")) {
            this.sellan = localStorage.getItem("lang")
            this.setLang(this.sellan)

        } else {
            localStorage.setItem("lang", "cn")
        }

    },
    methods: {
        setLang(val) {
            console.log(val)
            this.sellan = val
            this.$i18n.locale = val// this.$i18n.locale === 'zh-cn' ? 'en' : 'zh-cn';
            localStorage.setItem("lang", val)
            document.title = this.$t('index.title')// "abcd"
        },
        goto(page) {
            this.$router.push(page);
            // location.reload();
        },
        switchLang(val) {
            this.setLang(val)
        },
    }
}
</script>
<style>
.lang-btn {
    font-size: 14px;
    color: #fff;
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
}
</style>