<template>
    <body id="page-top" class="sub-page">
        <div class="wrap-body">

            <!--////////////////////////////////////Header-->
            <header>
                <div class="wrap-header">
                    <Lang @switchLang="switchLang"></Lang>
                    <!---Main Header--->
                    <div class="main-header" :style="`background: url(${showpic}) center center;background-size: cover;`">
                        <div class="bg-overlay">
                            <!---Hero Content--->
                            <div class="hero-content-wrapper">
                                <div class="hero-content">

                                    <h1 class="hero-lead wow fadeInLeft" data-wow-duration="1.5s">Our Services</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!---Top Menu--->
                    <top_menu @switchLang="switchLang" :lang="sellan"></top_menu>

                </div>
            </header>

            <!--////////////////////////////////////Container-->
            <section id="page-content">
                <div class="wrap-container zerogrid">
                    <div class="crumbs">
                        <ul>
                            <!-- <li><a href="index.html">{{ $t('index.home') }}</a></li> -->
                            <li><a href="javascript:;">{{ $t('index.service') }}</a></li>
                            <li><a href="javascript:;">{{ title }}</a></li>
                        </ul>
                    </div>
                    <div id="about-us">
                        <article class="post-entry single-post">
                            <div class="wrap-post">
                                <div class="entry-header">
                                    <h1 class="entry-title">{{ title }}</h1>

                                </div>

                                <div class="entry-content">
                                    <div class="excerpt">
                                        <!-- <p>{{ $t('index.service11') }}</p><br><br>
                                        <p><i class="fa fa-pencil-square"></i>{{ $t('index.service3') }}</p><br><br>
                                        <p><i class="fa fa-file-text"></i>{{ $t('index.service4') }}</p><br> -->
                                        <p v-html="content"></p>
                                    </div>

                                </div>
                            </div>
                        </article>

                    </div>
                </div>
            </section>

            <!--////////////////////////////////////Footer-->

            <foot></foot>


        </div>
    </body>
</template>
<script>
import * as api from "../api/api"
import top_menu from "@/components/top_menu.vue"
import Lang from "@/components/lang.vue"
import foot from "@/components/foot.vue"
export default {
    components: {
        top_menu,
        Lang,
        foot
    },
    data() {
        return {
            sellan: "cn",
            section: "story",
            showpic: "images/service.jpg",
            content: "",
            title: ""
        }
    },
    mounted() {

        this.section = this.$route.params.sec
        console.log(this.section)

        if (localStorage.getItem("lang")) {
            this.sellan = localStorage.getItem("lang")
            this.setLang(this.sellan)

        } else {
            localStorage.setItem("lang", "cn")
        }
        this.get_data()
    },
    methods: {
        setLang(val) {
            console.log(val)
            this.sellan = val
            this.$i18n.locale = val// this.$i18n.locale === 'zh-cn' ? 'en' : 'zh-cn';
            localStorage.setItem("lang", val)
            document.title = this.$t('index.title')// "abcd"
        },
        get_data() {
            api.getRequest('/contents/' + this.section,
                {}).then(resp => {
                    console.log(this.sellan)

                    this.content = resp.data.detail
                    this.title = resp.data.title

                    if (this.sellan == 'en') {
                        this.content = resp.data.detailEn
                        this.title = resp.data.titleEn
                    }
                    if (this.sellan == 'sp') {
                        this.content = resp.data.detailSp
                        this.title = resp.data.titleSp
                    }
                })
        },
        switchLang(val) {
            this.section = this.$route.params.sec
            this.sellan = val
            this.get_data()
        }
    }
}
</script>