import Vue from "vue";
import VueRouter from "vue-router";
import VueI18n from 'vue-i18n'


import index from "@/views/index"
import content from "@/views/content"
import products from "@/views/products"
import service from "@/views/service"
import service1 from "@/views/service1"
import contact from "@/views/contact"



const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    meta: {
      title: {
        en: '嘉松有限公司',
        cn: 'Jiasong'
      },
      keepAlive: false
    },
    component: index,
  },
  {
    path: "/about/:sec",
    name: "about",
    meta: {
      title: {
        en: '嘉松有限公司',
        cn: 'Jiasong'
      },
      keepAlive: false
    },
    component: content,
  },
  {
    path: "/products/:sec",
    name: "products",
    meta: {
      title: {
        en: '嘉松有限公司',
        cn: 'Jiasong'
      },
      keepAlive: false
    },
    component: products,
  },
  {
    path: "/service/:sec",
    name: "service",
    meta: {
      title: {
        en: '嘉松有限公司',
        cn: 'Jiasong'
      },
      keepAlive: false
    },
    component: service,
  },
  {
    path: "/service1/:sec",
    name: "service1",
    meta: {
      title: {
        en: '嘉松有限公司',
        cn: 'Jiasong'
      },
      keepAlive: false
    },
    component: service1,
  },
  {
    path: "/contact",
    name: "contact",
    meta: {
      title: {
        en: '嘉松有限公司',
        cn: 'Jiasong'
      },
      keepAlive: false
    },
    component: contact,
  },

]


const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    // const lang = localStorage.getItem("lang") || 'cn'
    // console.log(lang)
    // VueI18n.locale = lang
    // document.title = to.meta.title[VueI18n.locale];
  }
  next();
})



export default router
